<template>
  <div v-if="rateCard" class="feature-product-item">
    <div
      class="feature-product-row row between-xs middle-xs"
      :class="{ inactive2: isInactive }"
    >
      <span class="k-toptitle col-xs-4"
        >{{ productName }}
        <span v-if="getAdjustmentName(rateCard)"
          >- {{ getAdjustmentName(rateCard) }}</span
        ></span
      >
      <span v-if="readonly" class="cpmvalue dollarvalue col-xs-1">{{
        rateCard.defaultValue
      }}</span>
      <input
        v-else
        type="text"
        class="rangemax col-xs-1"
        placeholder="CPM"
        :value="rateCard.defaultValue"
        @change="onChangeDefaultValue"
      />
    </div>

    <k-toggle
      v-if="showToggle"
      class="rate-toggle-btn"
      size="1x"
      :checked="rateCard.enabled"
      :disabled="true"
    />
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import { rateCardItemsApi } from "adready-api/api/rate-cards";

export default {
  name: "ProductTypeRow",
  mixins: [],

  props: {
    rateCardItem: {
      type: Object,
      required: true
    },
    productName: {
      type: String,
      required: true
    },
    showToggle: {
      type: Boolean,
      required: false,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      rateCard: { ...this.rateCardItem }
    };
  },

  computed: {
    allKpiOptions: get("common/allKpiOptions"),
    targetingElements: get("common/targetingElements"),

    isInactive() {
      return this.rateCard && !this.rateCard.enabled;
    }
  },

  methods: {
    getAdjustmentName(rateCard) {
      if (rateCard.adjustmentModel === "TARGETING") {
        const targeting = this.targetingElements.find(
          t => t.id === rateCard.adjustmentId
        );
        if (targeting) {
          return targeting.name;
        }
      }
      if (rateCard.adjustmentModel === "KPI") {
        const kpi = this.allKpiOptions.find(
          k => k.id === rateCard.adjustmentId
        );
        if (kpi) {
          return kpi.name;
        }
      }
      return undefined;
    },

    saveRateCard(rateCard) {
      rateCardItemsApi
        .update(rateCard.productTypeId, rateCard)
        .then(updated => {
          this.rateCard = updated;
        });
    },

    // events
    onChangeDefaultValue(val) {
      this.rateCard.defaultValue = parseFloat(val.target.value);
      this.saveRateCard(this.rateCard);
    }
  }
};
</script>
