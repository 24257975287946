<template>
  <div v-if="group && group.length > 0" class="social-feature-item">
    <div
      class="feature-header row between-xs middle-xs"
      @click="isOpen = !isOpen"
    >
      <!-- Social product grouping - set costplus value for all products in group -->
      <div
        class="social-features col-xs row middle-xs"
        :class="{ disabled: readonly }"
      >
        <span class="col-xs-3">{{ name }}</span>

        <k-toggle
          v-if="showToggle"
          class="rate-toggle-btn"
          size="1x"
          :checked="true"
        />

        <div class="costplus-wrap">
          <span class="rate-default">{{ costPlusValue }}</span>
        </div>
      </div>

      <!-- Toggle for social products -->
      <div v-if="!isOpen">
        <font-awesome-icon :icon="['fas', 'angle-right']" class="width-arrow" />
      </div>
      <div v-else>
        <font-awesome-icon :icon="['fas', 'angle-down']" class="width-arrow" />
      </div>
    </div>
    <!-- Collapsible panel showing social products -->
    <transition name="fade">
      <div v-if="isOpen" class="feature-content row between-xs middle-xs">
        <product-type-toggle
          v-for="(node, index) in group"
          :key="index"
          :node="node"
          :class="{ disabled: readonly }"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { rateCardItemsApi } from "adready-api/api/rate-cards";

export default {
  name: "SocialGroup",

  mixins: [],

  props: {
    name: {
      type: String,
      required: true
    },

    group: {
      type: Array,
      required: true
    },

    showToggle: {
      type: Boolean,
      required: false,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      displayAccordionListStyle: "none",
      isOpen: false,
      localGroup: this.group
    };
  },

  computed: {
    costPlusValue() {
      return "COSTPLUS or FIXED MARGIN (depending on contracts)";
    }
  },

  methods: {
    // events
    onClickCost(ev) {
      ev.stopPropagation();
    },

    saveRateCard(rateCard) {
      delete rateCard._node;
      rateCardItemsApi.update(rateCard.productTypeId, rateCard);
    },

    onChangeCost(ev) {
      this.localGroup.forEach(node => {
        const rateCard = node._rateCard;
        if (rateCard) {
          rateCard.costPlus = parseFloat(ev.target.value);
          this.saveRateCard(rateCard).then(updated => {
            node._rateCard = updated;
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.social-feature-item {
  padding: 0;

  &:last-child .feature-header {
    margin: 0;
    border: none;
  }

  .feature-header {
    padding: 20px;
    margin: 0 0 20px;
    border-bottom: 1px $grey_8 solid;
  }

  .social-features {
    padding: 0;

    span {
      padding: 0;
      margin: 0 20px 0 0;
      font-size: 13px;
      font-weight: 500;
      color: $grey_2;
      text-transform: uppercase;
    }
    .rate-toggle-btn {
      margin: 0 20px 0 0;
      font-size: 24px;
    }
    .costplus-wrap span {
      margin: 0 20px 0 0;
      font-size: 12px;
      font-weight: bold;
    }
  }
}
.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
