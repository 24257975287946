import _ from "underscore";

import { dedupe } from "./dedupe";
import store from "./../store";
import masterDataApi from "adready-api/api/masterData";

/**
 * Put a frozen copy of the given val into the vuex store and return the frozen
 * copy.
 *
 * As master-data is by definition immutable (in our case, for a given account),
 * we can safely 'freeze' these objects. This way, Vue will not setup proxies
 * and make these objects reactive, saving us a bit of overhead when dealing
 * with them.
 *
 * When loading data, we SET them into the store, e.g.,
 *
 * state.foo_options = newOptionData;
 *
 * so we will still trigger reactive changes on the basis of 'foo_options' being
 * set to a new object/array. We just don't expect that items *within* that
 * object to change once loaded.
 *
 * @param {String} key Key to store data with
 * @param {Object} val Value
 */
function set(key, val) {
  let frozen;
  if (Array.isArray(val)) {
    frozen = val.map(v => Object.freeze(v));
  } else {
    frozen = Object.freeze(val);
  }
  store.set(key, frozen);
  return frozen;
}

const masterDataLoaders = {
  /**
   * Checks if the given data is available (already loaded and in the store)
   * @param {String} key
   */
  isDataLoaded(key) {
    const data = store.get(key);
    return data && !_.isEmpty(data);
  },

  async loadMasterData(api) {
    const key = `common/${api}`;
    if (this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }

    return dedupe(key, () => {
      const fn = masterDataApi[api];
      if (!fn) {
        return Promise.reject(
          new Error(`error: missing API method for ${api}`)
        );
      }

      return fn()
        .then(res => {
          set(key, res);
          return res;
        })
        .catch(e => this.forkliftErrHandler(e, api));
    });
  },

  async loadProductCategories() {
    return this.loadMasterData("productCategories");
  },

  async loadKpiMasters() {
    return this.loadMasterData("allKpiOptions");
  },
  async loadTargetingElements() {
    return this.loadMasterData("targetingElements");
  }
};

export default masterDataLoaders;
