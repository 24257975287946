<template>
  <div class="feature-product-item">
    <div
      class="feature-product-row row between-xs middle-xs"
      :class="{ inactive2: !rateCard.enabled }"
    >
      <span class="k-toptitle col-xs-4"
        >{{ productName }}
        <span v-if="getAdjustmentName(rateCard)"
          >- {{ getAdjustmentName(rateCard) }}</span
        ></span
      >

      <k-toggle
        v-if="!readonly && showToggle"
        class="rate-toggle-btn"
        size="1x"
        :checked="true"
        :disabled="true"
      />

      <div class="costplus-wrap col-xs-5">
        <span class="rate-default">{{ costPlusValue }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import { rateCardItemsApi } from "adready-api/api/rate-cards";

export default {
  name: "ProductTypeRow",

  mixins: [],

  props: {
    rateCardItem: {
      type: Object,
      required: true
    },
    productName: {
      type: String,
      required: true
    },
    showToggle: {
      type: Boolean,
      required: false,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      rateCard: { ...this.rateCardItem }
    };
  },

  computed: {
    allKpiOptions: get("common/allKpiOptions"),
    targetingElements: get("common/targetingElements"),
    costPlusValue() {
      return "COSTPLUS or FIXED MARGIN (depending on contracts)";
    }
  },

  methods: {
    getAdjustmentName(rateCard) {
      if (rateCard.adjustmentModel === "TARGETING") {
        const targeting = this.targetingElements.find(
          t => t.id === rateCard.adjustmentId
        );
        if (targeting) {
          return targeting.name;
        }
      }
      if (rateCard.adjustmentModel === "KPI") {
        const kpi = this.allKpiOptions.find(
          k => k.id === rateCard.adjustmentId
        );
        if (kpi) {
          return kpi.name;
        }
      }
      return undefined;
    },
    saveRateCard(rateCard) {
      rateCardItemsApi
        .update(rateCard.productTypeId, rateCard)
        .then(updated => {
          this.rateCard = updated;
        });
    },
    onChangeCost(ev) {
      this.rateCard.costPlus = parseFloat(ev.target.value);
      this.saveRateCard(this.rateCard);
    }
  }
};
</script>
<style lang="scss" scoped>
.rate-default {
  font-size: 12px;
}
</style>
