import store from "./../store";
import productHierarchyApi from "adready-api/api/product-hierarchy";
import { ProductHierarchy } from "adready-api/model/product-hierarchy";

const accountLoaders = {
  async loadProductHierarchy(accountId, accountType, force) {
    const key = "common/productHierarchy";
    if (!force && this.isDataLoaded(key)) {
      return store.get(key); // nothing to do, bail out
    }

    return productHierarchyApi
      .index({ accountId, accountType })
      .then(nodes => {
        const hierarchy = new ProductHierarchy(nodes);
        return this.loadProductCategories().then(categories => {
          hierarchy.setCategories(categories);
          store.set(key, hierarchy);
          return hierarchy;
        });
      })
      .catch(e => this.forkliftErrHandler(e, "productHierarchy"));
  }
};

export default accountLoaders;
