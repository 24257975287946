<template>
  <div class="feature-row">
    <div
      class="feature-header row between-xs middle-xs"
      @click="isOpen = !isOpen"
    >
      <span>{{ node._name }}</span>
      <div>
        <font-awesome-icon
          v-if="isOpen"
          :icon="['fas', 'angle-down']"
          class="width-arrow"
        />
        <font-awesome-icon
          v-else
          :icon="['fas', 'angle-right']"
          class="width-arrow"
        />
      </div>
    </div>
    <div v-if="isOpen" class="feature-content opened">
      <!-- Headers for nested nodes (products and possibly categories) -->
      <div class="rate-label-wrap row between-xs middle-xs">
        <span class="rate-label col-xs-4">Product</span>
        <span class="rate-label col-xs-1">CPM</span>
      </div>

      <div class="feature-product">
        <div v-for="child in node._children" :key="child.id">
          <div
            v-if="hasRateCards(child) && isCostPlusProduct(child)"
            class="feature-product-wrap"
          >
            <product-type-row-cost-plus
              v-for="rateCardItem in child._rateCards"
              :key="rateCardItem.id"
              :rateCardItem="rateCardItem"
              :product-name="child._name"
              :readonly="readonly"
              :class="{ disabled: readonly }"
            />
          </div>
          <div
            v-if="hasRateCards(child) && !isCostPlusProduct(child)"
            class="feature-product-wrap"
          >
            <product-type-row
              v-for="rateCardItem in child._rateCards"
              :key="rateCardItem.id"
              :rateCardItem="rateCardItem"
              :product-name="child._name"
              :readonly="readonly"
              :class="{ disabled: readonly }"
            />
          </div>
          <div v-if="child.type === 'CATEGORY'" class="feature-product-wrap">
            <product-category-row :node="child" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RT_COST_PLUS } from "./../../constant";
import { isBlank } from "adready-api/helpers/common";

export default {
  name: "ProductCategoryRow",

  mixins: [],

  props: {
    node: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      isOpen: false,
      RT_COST_PLUS
    };
  },

  methods: {
    hasRateCards(child) {
      if (isBlank(child._rateCards)) {
        return false;
      }
      return true;
    },

    isCostPlusProduct(child) {
      if (
        child.type === "PRODUCT" &&
        child._rateCards &&
        child._rateCards[0].rateType === RT_COST_PLUS
      ) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.rate-label-wrap {
  padding: 0;
  margin: 10px 20px 20px;
  font-size: 14px;
  font-weight: 600;

  span {
    padding: 0;
  }
}

.feature-product {
  padding: 20px;
  background-color: $grey_9;
  border-bottom: 1px $grey_8 solid;
}

.feature-product-wrap {
  margin: 0 0 20px;

  &:last-child {
    margin: 0;
    border: none;
  }
}
.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
