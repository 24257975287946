import _ from "underscore";

import masterDataLoaders from "./master-data-loaders";
import accountLoaders from "./account-loaders";

// const computed = {
//   // TODO: this might not be necessary. maybe better to just define the getters wherever they are actually needed?
//   // create getter for all keys in options obj
//   // (see store/io/index.js for ref)
//   ...get('io/options@*'),
// };

const methods = {
  forkliftErrHandler(e, key) {
    if (process.env.NODE_ENV !== "production") {
      console.log(key, e);
    }
    if (this.errors) {
      if (Array.isArray(this.errors)) {
        this.errors.push(e);
      }
    }
  },

  ...masterDataLoaders,
  ...accountLoaders
};

const forklift = {
  // computed,
  methods
};

/**
 * Create a mixin which stocks (loads) the given list of data.
 *
 * @param  {...any} opts List of master data to load
 */
export function stock(...opts) {
  if (_.isEmpty(opts)) {
    return {};
  }
  const mixin = {
    asyncComputed: {}
  };

  opts.forEach(opt => {
    // create asyncComputed so we can always return a default value
    // while the data is being fetched
    mixin.asyncComputed[opt] = {
      default: [],
      async get() {
        const loader = `load${opt[0].toUpperCase()}${opt.slice(1)}`;
        const fn = this[loader];
        if (!fn) {
          // warn
          console.warn(`couldn't find loader method ${loader}`);
          return Promise.reject(
            new Error(`couldn't find loader method ${loader}`)
          );
        }
        return fn();
      }
    };
  });

  return { ...forklift, ...mixin };
}

export default forklift;
