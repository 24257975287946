<template>
  <div class="social-channel row between-xs middle-xs">
    <span>{{ node._name }}</span>
    <k-toggle
      v-if="showToggle"
      class="rate-toggle-btn"
      size="2x"
      :checked="true"
    />
  </div>
</template>

<script>
export default {
  name: "ProductTypeToggle",
  props: {
    node: {
      type: Object,
      required: true
    },
    showToggle: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.feature-content {
  .social-channel {
    flex: 0 0 auto;
    flex-basis: 49%;
    max-width: 49%;
    padding: 15px;
    margin: 0 5px 10px 0;
    font-size: 13px;
    background-color: $grey_9;
  }
}
</style>
