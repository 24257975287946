<template>
  <div class="feature-row social-features">
    <div
      class="feature-header row between-xs middle-xs"
      @click="isOpen = !isOpen"
    >
      <span>{{ node._name }}</span>
      <div v-if="!isOpen">
        <font-awesome-icon :icon="['fas', 'angle-right']" class="width-arrow" />
      </div>
      <div v-else>
        <font-awesome-icon :icon="['fas', 'angle-down']" class="width-arrow" />
      </div>
    </div>
    <transition name="fade">
      <div v-if="isOpen" class="feature-content">
        <social-group
          v-for="(group, name) in socialfeatures"
          :key="name"
          :name="name"
          :group="group"
          :readonly="readonly"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { isBlank } from "adready-api/helpers/common";

export default {
  name: "SocialProductCategoryRow",

  props: {
    node: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      displayAccordionListStyle: "none",
      isOpen: false
    };
  },

  computed: {
    socialfeatures() {
      // return products grouped by channel
      const map = {
        "Facebook / Instagram": [],
        Twitter: [],
        Linkedin: [],
        Pinterest: [],
        Other: []
      };

      this.node._children.forEach(f => {
        const n = f._name.toLowerCase();
        if (isBlank(f._rateCards)) {
          return;
        }
        if (n.indexOf("facebook") >= 0 || n.indexOf("instagram") >= 0) {
          map["Facebook / Instagram"].push(f);
        } else if (n.indexOf("twitter") >= 0) {
          map.Twitter.push(f);
        } else if (n.indexOf("linkedin") >= 0) {
          map.Linkedin.push(f);
        } else if (n.indexOf("pinterest") >= 0) {
          map.Pinterest.push(f);
        } else {
          map.Other.push(f);
        }
      });

      return map;
    }
  },

  methods: {}
};
</script>
