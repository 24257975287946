<template>
  <div class="ratecard-container">
    <div class="ratecard-header row between-xs middle-xs marginb-40">
      <div v-if="readonly" class="k-toptitle">Rate Card</div>
      <div v-else class="k-toptitle">Rate Card: Adjust Product CPMs</div>

      <div class="markup-wrap">
        <div class="col-xs-1 close" @click="$emit('close')">
          <font-awesome-icon
            :icon="['fal', 'times']"
            size="2x"
          ></font-awesome-icon>
        </div>
      </div>
    </div>

    <div v-if="hierarchy">
      <!-- :class="{ 'social-rate-wrap': key == 'social' }" -->
      <div
        v-for="node in hierarchy.graph"
        :key="node.id"
        class="ratecard-feature"
      >
        <product-category-row
          v-if="hasRateCard(node)"
          :node="node"
          :readonly="readonly"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { ACCOUNT_TYPE_PLUS, RATE_CARD_WRITE } from "./../constant";
import { isBlank } from "adready-api/helpers/common";
import { get } from "vuex-pathify";
import ProductCategoryRow from "./ratecard/product-category-row.vue";
import ProductTypeRow from "./ratecard/product-type-row.vue";
import ProductTypeRowCostPlus from "./ratecard/product-type-row-cost-plus.vue";

import SocialProductCategoryRow from "./ratecard/social-product-category-row.vue";
import SocialGroup from "./ratecard/social-group.vue";
import ProductTypeToggle from "./ratecard/product-type-toggle.vue";

Vue.component("ProductCategoryRow", ProductCategoryRow);
Vue.component("ProductTypeRow", ProductTypeRow);
Vue.component("ProductTypeRowCostPlus", ProductTypeRowCostPlus);

Vue.component("SocialProductCategoryRow", SocialProductCategoryRow);
Vue.component("SocialGroup", SocialGroup);
Vue.component("ProductTypeToggle", ProductTypeToggle);

import { rateCardsApi } from "adready-api/api/rate-cards";

import forklift from "./../mixin/forklift-mixin";
import authMixin from "./../mixin/authz-mixin";
import store from "./../store";

export default {
  name: "RateCard",

  mixins: [forklift, authMixin],

  data() {
    return {
      hierarchy: undefined
    };
  },

  mounted() {
    this.loadKpiMasters();
    this.loadTargetingElements();
    this.loadProductHierarchy(this.adreadyAccountId, ACCOUNT_TYPE_PLUS).then(
      hierarchy => {
        rateCardsApi
          .index(this.adreadyAccountId, { accountType: ACCOUNT_TYPE_PLUS })
          .then(rateCard => {
            store.commit("SET_RATECARD", rateCard);
            // create a copy of the hierarchy (to avoid state mutation) with the
            // rate card attached to each product node. we can put this in the
            // store later on, if needed.
            const copy = hierarchy.inertClone();
            copy.setRateCard(rateCard);
            this.hierarchy = copy;
          });
      }
    );
  },

  computed: {
    adreadyAccountId: get("adreadyAccount@id"),
    authentication: get("authentication"),
    rateCard: get("rateCard"),

    readonly() {
      if (this.hasPermission(RATE_CARD_WRITE)) {
        return false;
      }
      return true;
    }
  },

  methods: {
    hasRateCard(node) {
      if (isBlank(node) || isBlank(node._children)) {
        return false;
      }
      return node._children.some(child => {
        return !isBlank(child._rateCards);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.ratecard-container {
  padding-left: 30px;
  padding-right: 30px;

  .top-text {
    margin: 0 0 20px;
    font-size: 13px;
  }

  .ratecard-header {
    padding: 0;

    .markup-wrap {
      display: inline-block;
      padding: 15px;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}
</style>

<style lang="scss">
.ratecard-feature {
  padding: 0;
  margin: 0;

  .feature-row {
    margin: 0px auto 20px;
    border: 1px $grey_8 solid;

    .feature-header {
      padding: 20px;
      font-size: 14px;
      font-weight: 500;
      color: $grey_2;
      text-transform: uppercase;
      cursor: pointer;
      user-select: none;
      background: $white;
      border-top: none;
    }

    .feature-content {
      padding: 0 20px 20px;
      transition: all 0.5s ease-in-out;

      &.closed {
        opacity: 0;
      }
      &.opened {
        opacity: 1;
      }
    }
  }

  .feature-product-item {
    margin: 0 0 20px 0;
    border-bottom: 1px solid #fcfcfc;

    .feature-product-row {
      margin: 0 0 20px;
    }

    .k-toptitle {
      padding: 0;
      font-size: 13px;
    }
    .feature-product-slider {
      padding: 0;

      .product-type-slider {
        padding: 0;
        margin: 0 0 0 20px;
        border: none;
        .range-slider {
          max-width: 100%;
        }
      }
    }
    .cpmvalue {
      padding: 0;
      font-size: 17px;
      font-weight: 400;
      color: $grey_2;
      text-align: left;
    }
    .rate-toggle-btn {
      font-size: 27px;
    }
  }
  .rangemin,
  .rangemax {
    width: 70px;
    padding: 10px 10px 10px 16px;
  }
}
</style>
